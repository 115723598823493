import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about-wrapper">
      <h2   data-aos="zoom-out-up" >
        About <span className="rightSpan_circle">Us</span>
      </h2>
      <div className="about-content">
        <div
         data-aos="fade-right"
         className="content-left">
          <h3>Who Are We?</h3>
          <p>
            Welcome to the beating heart of digital innovation where we
            transcend mere tech passion while creating the landscapes of digital
            tomorrow. Our creative arena breathes life into ideas, transforming
            them into tech marvels. Armed with inventive creativity and a tech
            arsenal from the future, we reimagine the boundaries of possibility.
          </p>
          <Link to="/portfolio">
          <button className="gradient-blue-button">Read Case Studies</button>
          </Link>
         
        </div>
        <div data-aos="fade-left" className="content-right">
          <img src="../../../images/home/about-right-image.webp" alt="..." />
        </div>
      </div>
    </div>
  );
};

export default About;
