import React, { useEffect } from 'react'
import ServicesBanner from '../components/services/servicesbanner/servicesBanner'
import ServicesContent from '../components/services/servicescontent/servicesContent'
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {

  useEffect(() => {
    AOS.init();
  }, []);
  return (

    <div>
      <ServicesBanner />
      <ServicesContent />
    </div>
  )
}

export default Services
