import React, { useEffect } from "react";
import Cards from "./cards";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Expertise = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const img1 = "../../../images/home/expert-card-1.png";
  const img2 = "../../../images/home/expert-card-2.png";
  const img3 = "../../../images/home/expert-card-3.png";
  const img4 = "../../../images/home/expert-card-4.png";
  const img5 = "../../../images/home/expert-card-5.png";
  const img6 = "../../../images/home/expert-card-6.png";

  const text1 = "BLOCKCHAIN DEVELOPMENT";
  const text2 = "ARTIFICIAL INTELLIGENCE";
  const text3 = "METAVERSE";
  const text4 = "GAME DEVELOPMENT";
  const text5 = "DEVOPS SERVICES";
  const text6 = "SALES & MARKETING";

  return (
    <div className="expertise-wrapper">
      <h2 data-aos="zoom-out-up">
        <span className="leftSpan_circle">OUR</span> SERVICES
      </h2>
      <div className="card-container">
        <img
          className="lineLeft"
          src="../../../images/home/expertiseLine.png"
          alt=""
        />
        <img
          className="lineRight"
          src="../../../images/home/expertiseLine.png"
          alt=""
        />
        <img
          className="lineHori"
          src="../../../images/home/expertiseHori.png"
          alt=""
        />

        {/* data-aos="zoom-in-right" */}
        <Cards
          animate="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          cardImg={img1}
          cardText={text1}
        />

        <Cards
          animate="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          cardImg={img2}
          cardText={text2}
        />

        <Cards
          animate="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
          cardImg={img3}
          cardText={text3}
        />

        <Cards
          animate="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          cardImg={img4}
          cardText={text4}
        />
        <Cards animate="zoom-in-up" cardImg={img5} cardText={text5} />

        <Cards
          animate="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
          cardImg={img6}
          cardText={text6}
        />
      </div>
    </div>
  );
};

export default Expertise;
