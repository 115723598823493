import React from "react";

const ProjectCards = (props) => {
  return (
    <div className="portfolioCards">
      <img src={props.cardBg} alt="" />
      <div className="projInfo">
        <h4>{props.projectName}</h4>
        <p className="text-left">
        {props.projectDetails}
        </p>
      </div>
    </div>
  );
};

export default ProjectCards;
