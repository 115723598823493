import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import twitterIcon from "../../src/images/home/icons8-twitter-32.png";
import DateObject from "react-date-object";
import { Link } from "react-router-dom";
const Footer = () => {
  // const [scrollY, setScrollY] = useState(0);
  // const [showArrow, setShowArrow] = useState(false);
  var date = new DateObject();

  // const handleScroll = () => {
  //   let heightToHidden = 300;
  //   const winScroll =
  //     document.body.scrollTop || document.documentElement.scrollTop;

  //   //setScrollY(window.scrollY);

  //   if (winScroll > heightToHidden) {
  //     setShowArrow(true);
  //   } else {
  //     setShowArrow(false);
  //   }
  // };

  // useEffect(() => {
  //   const handleScrollDirection = () => {
  //     // Always show the arrow when scrolling
  //     setShowArrow(true);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [scrollY]);

  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };
  return (
    <div id="#scrollTop" className="footer-container">
      <img
        className="footerGrid"
        src="../../images/home/footerGrid.png"
        alt=""
      />
      <div className="footerWrapper  ">
        <div className="footer-col-1">
          <img
            className="footerLogo"
            src="../../images/home/metLogo.png"
            alt="..."
          />
          <p className="footer-para">
            Metaloops Solutions stands as a dynamic IT powerhouse, spearheading
            innovation and excellence across a spectrum of services. With a
            focus on innovation, our team creates robust and scalable technology
            solutions, ensuring clients stay ahead in the digital landscape.
          </p>
          <div className="social-media-wrapper">
            {/* <Icon className="iconStyle" icon="ic:baseline-discord" /> */}
            <a
              href="https://www.linkedin.com/company/metaloops-solutions/"
              target="blank"
            >
              <Icon
                className="iconStyle"
                icon="uil:linkedin"
                width="24"
                height="24"
              />
            </a>

            <a href="https://www.instagram.com/metaloop_sol/" target="blank">
              {" "}
              <Icon className="iconStyle" icon="mdi:instagram" />
            </a>
            <a
              href="https://www.facebook.com/metaloopsSolutions/"
              target="blank"
            >
              <Icon className="iconStyle" icon="ic:baseline-facebook" />
            </a>

            {/* <Icon className="iconStyle" icon="arcticons:x-twitter" width="24" height="24" /> */}
            <a href="https://twitter.com/Metaloop_off" target="blank">
              {" "}
              <Icon
                className="iconStyle"
                icon="fa6-brands:x-twitter"
                width="24"
                height="24"
              />
            </a>

            {/* <img src={twitterIcon} alt="" className="iconStylet" /> */}
            {/* <Icon className="iconStyle" icon="pajamas:twitter" /> */}
          </div>
        </div>
        <div className="footer-col-2">
          <h5>Pages</h5>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/termscondition">Terms of service</a>
        </div>

        <div className="footer-col-3">
          <h5>Explore</h5>
          <a href="/about">About</a>
          <a href="/contact">Contact</a>
        </div>
        {/* <div className="footer-col-4">
          <a href="#scrollTop">
            <button style={{ border: "none" }}>
              <img
                style={{ display: showArrow ? "flex" : "none" }}
                onClick={scrollToTop}
                className="footer-arrow-up"
                src="../../images/home/arrowUp.png"
                alt="..."
              />
            </button>
          </a>
        </div> */}
      </div>
      <hr />
      <p className="allRights">@{date.year} Metaloop. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
