import React from "react";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GetInTouch = () => {
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    description: "",
    check: "",
  });
  const [formErrors, setFormErrors] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    check: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     const newErrors = {};

    // Check for empty fields
    // const emptyFields = Object.keys(formData).filter(
    //   (key) => !formData[key].trim()
    // );
    // if (emptyFields.length > 0) {
    //   toast.error("Please fill in all required fields");
    //   return;
    // }








    // const requiredFields = ["fname", "lname", "phone", "email", "description"];
    // const emptyFields = requiredFields.filter((field) => !formData[field].trim());
    // if (emptyFields.length > 0) {
    //   toast.error("Please fill in all required fields");
    //   return;
    // }



    // Check for empty fields
  const requiredFields = ["fname", "lname", "phone", "email", "description", "check"];
  const emptyFields = requiredFields.filter((field) => !formData[field].trim());
  if (emptyFields.length > 0) {
    toast.error("Please fill in all required fields");
    return;
  }

  // Validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (formData.email && !emailRegex.test(formData.email)) {
    toast.error("Please enter a valid email address");
    return;
  }
if (Object.keys(newErrors).length > 0) {
      // Display error toasts and update state for highlighting
      Object.keys(newErrors).forEach((key) => {
        toast.error(newErrors[key]);
      });
      setFormErrors(newErrors);
      return;
    }
    toast.success("Form submitted successfully!");

  // Reset form data
  setFormData({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    description: "",
    check:"",
  });
  };

  return (
    <div className="getInTouch-Wrapper">
      <div className="getInTouch-Container">
        <div className="content-left">
          <h4>Get in touch</h4>
          <form>
            <div className="name-wrapper">
              <div className="f-name">
                <label for="fname">First name</label> <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="First name"
                  value={formData.fname}
                  onChange={handleChange}
                />
              </div>

              <div className="l-name">
                <label for="lname">Last name</label> <br />
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="Last name"
                  value={formData.lname}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="email">
              <label className="contact-label" for="email">
                Email
              </label>{" "}
              <br />
              <input
                className="contact-input"
                type="email"
                id="email"
                name="email"
                placeholder="you@company.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="phone">
              <label className="contact-label" for="num">
                Phone number
              </label>{" "}
              <br />
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="CA"
                value={value}
                onChange={setValue}
                autoComplete="off"
                inputProps={{ placeholder: "Enter phone number" }}
              />
              {/* <input
                className="contact-input"
                type="text"
                id="num"
                name="num"
                placeholder="(555) 000-0000"
              /> */}
            </div>
            <div className="message">
              <label className="contact-label" for="msg">
                Message
              </label>{" "}
              <br />
              {/* <textarea
              type="text"
                className="contact-input"
                id=""
                cols="30"
                rows="7"
                value={formData.message}
                onChange={handleChange}
              ></textarea> */}
              <textarea
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="contact-input"
                //className="description"
              ></textarea>
            </div>
            <div className="checkBox">
              <input
                type="checkbox"
                id="checkbox1"
                name="checkbox1"
                value={formData.check}
                onChange={handleChange}
              />
              &nbsp; &nbsp;
              <label for="checkbox1">
                You agree to our friendly <u>privacy policy</u> .
              </label>
            </div>
            <button onClick={handleSubmit} className="getInTouchButton">
              Get In Touch
            </button>
            <ToastContainer />
          </form>
        
        </div>
        <div className="content-right"></div>
      </div>
    </div>
  );
};

export default GetInTouch;
