import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const TeamMember = (props) => {

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='personConainer' data-aos={props.animate}>
        <div className='employeeImg'>
            <img src={props.teamImages} alt="" />
        </div>
        <h4>{props.teamMembers}</h4>
        <p>{props.teamRole}</p>
    </div>
  )
}

export default TeamMember;