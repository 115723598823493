import React from "react";
import "./privacypolicy.scss";
const Privacypolicy = () => {
  const contentArray = [
    {
      heading: "1. Information We Collect:",
      paragraph:
        "We may collect personal information such as names, contact details, and project-related details when you engage with our services. This data is gathered to provide personalized and efficient Solutions tailored to your needs",
    },
    {
      heading: "2. How We Use Your Information:",
      paragraph:
        "Your information is used exclusively for legitimate business purposes, such as project collaboration, transaction processing, and improving our services. We do not sell or share your data with third parties without your explicit consent.",
    },
    {
      heading: " 3. Security Measures:",
      paragraph:
        "The security of your data is paramount to us. We employ advanced security measures, including encryption, secure networks, and regular audits, to safeguard your information against unauthorized access, disclosure, alteration, and destruction.",
    },
    {
      heading: "4. Data Retention and Deletion:",
      paragraph:
        "Our data retention policy at Metaloop Solutions is designed to keep your information only for the duration necessary for its original purposes. Upon completion of these purposes, we promptly initiate a secure deletion process, erasing all traces from our systems.",
    },
    {
      heading: "5. Ensuring Privacy and Peace of Mind in Digital Interactions:",
      paragraph:
        "Our commitment extends to continual reviews of our deletion protocols to comply with evolving privacy standards. In instances where data is retained for analytical purposes, we employ anonymization techniques to protect individual identities. Upon the successful deletion or anonymization of your data, we may send you a confirmation, emphasizing our dedication to transparency and your peace of mind throughout your digital journey with us.",
    },
    {
      heading: "6. Updates to Our Privacy Policy:",
      paragraph:
        "As technology and regulations evolve, so do our privacy practices. Any changes to our privacy policy will be communicated on this page, ensuring you're always informed about how your data is handled.",
    },
    {
      heading: "7. Your Consent Matters:",
      paragraph:
        "We operate on a consent-centric model, ensuring that any data collected is done with your explicit agreement. Your preferences matter, and you have control over how your information is utilized.",
    },
    {
      heading: "8. Contact Us:",
      paragraph:
        "If you have any questions or concerns about our privacy practices, we welcome you to reach out to our dedicated team. Our goal is to assist you and address any inquiries you may have. Your trust is of utmost importance to us, and we are steadfast in our commitment to making your digital journey with Metaloop Solutions secure, transparent, and worthy of your confidence.",
    },
  ];
  return (
    <div className="privacypolicy">
      <div className="privacytop">
        <h1 className="text-start">Privacy Policy</h1>
       <i className="text-center mt-4"><h4 className="mt-5">Your Digital Trust, Our Pledge</h4></i> 
        {/* <h3> Introduction</h3> */}
        <p>
          At Metaloop Solutions, your privacy is not just a matter of policy;
          it's a cornerstone of our commitment to providing a secure and trusted
          digital environment. We respect the importance of your personal
          information and aim to be transparent about how we collect, use, and
          protect it.
        </p>
      </div>
      {contentArray.map((content, index) => (
        <div className="privacycontent" key={index}>
          <h2>{content.heading}</h2>
          <p>{content.paragraph}</p>
        </div>
      ))}
      <div className="privacybottom">
        <p>Thank you for choosing us as your trusted digital partner.</p>
      </div>
    </div>
  );
};

export default Privacypolicy;
