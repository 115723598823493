import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../src/images/home/metaloopLogo.png";
import termslogo from "../../src/images/home/termscodition-logo.svg";
//import logo from "../../src/images/home/svgLogo.svg";

import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import mobilelogo from "../assets/images/mobilelogo.svg";

const Navbar = () => {
  const location2 = useLocation();
  const currentPath = location2.pathname;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const changedPages = [
    "/privacy-policy",
    "/termscondition",
    "*",
  ];
  const getAppropiateNavItemsColor = () =>
    changedPages.includes(location.pathname) ? { color: "black" } : {};

    const contactBackground = () => 
      changedPages.includes(location.pathname) ? { background: 'linear-gradient(90deg, #0072ff 0%, #00c6ff 50%, #0072ff 100%)', border : 'none' } : {};
    
  const { pathname } = location;
    const handleNavLinkClick = () => setShow(false); // Function to close Offcanvas on NavLink click
  return (
    <>
      


<div className="navbar-Wrapper">
  <div className="navContent">
    <div className="nav-logo">
      <Link to="/">
        <img src={`${
                changedPages.includes(location.pathname)
                  ? termslogo
                  : logo
              }`} alt="" />
      </Link>
    </div>
    <div className="navbar-nav">
      <ul>
        <li>
          <NavLink
            className="active"
            to="/"
            style={getAppropiateNavItemsColor}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
           
            style={getAppropiateNavItemsColor}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/services"
            
            style={getAppropiateNavItemsColor}
          >
            Services
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/portfolio"
           
            style={getAppropiateNavItemsColor}
          >
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blogs"
           
            style={getAppropiateNavItemsColor}
          >
            Blogs
          </NavLink>
        </li>
      </ul>
    </div>
    <div className="nav-contact" >
      <Link to="/contact"  >
        <button className="blue-button" style={contactBackground()}>Contact Us</button>
      </Link>
    </div>
  </div>
</div>

      <div className="mobile-navbar">
        <img src={mobilelogo} alt="" />
        <Button onClick={handleShow} className="menu-btn">
          <img src="../../images/home/icons8-menu-50.png" alt="Menu" />
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img
                src="../../images/home/logoMobile.svg"
                alt="Mobile Logo"
                className="logostyle"
              />
            </Offcanvas.Title>
            <img
              onClick={handleClose}
              src="../../images/home/logo-removebg-preview.png"
              alt="Close"
            />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              <li>
                <NavLink
                  className="active"
                  to="/"
                  style={{ color: currentPath === "/" ? "#0172ff" : "white" }} onClick={handleNavLinkClick}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  style={{
                    color: currentPath === "/about" ? "#0172ff" : "white",
                  }} onClick={handleNavLinkClick}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  style={{
                    color: currentPath === "/services" ? "#0172ff" : "white",
                  }} onClick={handleNavLinkClick}
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/portfolio"
                  style={{
                    color: currentPath === "/portfolio" ? "#0172ff" : "white",
                  }} onClick={handleNavLinkClick}
                >
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blogs"
                  style={{
                    color: currentPath === "/blogs" ? "#0172ff" : "white",
                  }} onClick={handleNavLinkClick}
                >
                  Blogs
                </NavLink>
              </li>
            </ul>
            <Link to="./contact">
              <button className="blue-button" onClick={handleNavLinkClick}>Contact Us</button>
            </Link>
          </Offcanvas.Body>
        </Offcanvas>
      </div>


    </>
  );
};

export default Navbar;
