import React, { useEffect } from 'react'
import Blogbanner from "../components/blogs/blogBanner/blogbanner"
import BlogsContent from '../components/blogs/blogsContent/blogsContent'
import AOS from "aos";
import "aos/dist/aos.css";
const Blogs = () => {

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
        <BlogsContent />
    </div>
  )
}

export default Blogs
