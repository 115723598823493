import React, { useEffect } from "react";
import Heading from "./heading";
import AOS from "aos";
import "aos/dist/aos.css";

const OurMission = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const headName = "Mission";
  return (
    <div className="missionWrapper">
      <img
        className="glowLeft"
        src="../../../images/about/glowLeft.png"
        alt=""
      />
      <img
        className="glowRight"
        src="../../../images/about/glowRight.png"
        alt=""
      />
      <div className="missionLeft">
        <div className="centerDiv"></div>
        <img src="../../../images/about/ourMissionImg.webp" alt="" />
      </div>
      <div
        // data-aos="fade-left"
        className="missionRight"
      >
        <Heading haedingName={headName} />
        <p className="mt-10">
          Metaloop Solutions is dedicated to being a technology powerhouse,
          driving progress through cutting-edge services. Our mission is to
          facilitate transformative Solutions, nurturing a future where
          businesses excel in the digital landscape.
        </p>

        <p className="mt-4">
          We strive to be at the forefront of technological evolution, creating
          transformative Solutions that elevate businesses in the digital era.
          By leveraging our expertise, we aim to enable clients to navigate the
          complexities of the modern landscape, ensuring their sustained growth
          and success.
        </p>

        <p className="mt-4">
          As a trusted partner, Metaloop Solutions is dedicated to driving
          positive change and shaping a future where innovation thrives and
          businesses flourish. Collaboration is not just a process but also the
          cornerstone of our success.
        </p>
      </div>
    </div>
  );
};

export default OurMission;
