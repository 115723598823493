import React from "react";
import "./privacypolicy.scss";

const Termscondition = () => {
  const contentArray = [
    {
      heading: "1. Acceptance of Terms:",
      paragraph:
        "By accessing or using our website and services, you acknowledge that you have read, understood, and agree to be bound by these terms. If you do not agree with any part of these terms, please refrain from using our website and services.",
    },
    {
      heading: "2. Changes to Terms",
      paragraph:
        "Metaloop Solutions reserves the right to update, change, or replace any part of these terms at its sole discretion. It is your responsibility to check this page periodically for changes. Your continued use of the website following any modifications constitutes acceptance of those changes.",
    },
    {
      heading: " 3. Use of Services",
      paragraph:
        "You agree to use Metaloop Solutions services for lawful purposes and in compliance with all applicable laws and regulations. You may not use our services for any illegal or unauthorized purpose, nor may you violate any laws in your jurisdiction.",
    },
    {
      heading: "4. Intellectual Property",
      paragraph:
        "All content, trademarks, logos, and intellectual property displayed on the Metaloop Solutions website are the property of Metaloop Solutions and are protected by applicable copyright and trademark laws. You may not reproduce, distribute, or use any content without the express written permission of Metaloop Solutions",
    },
    {
      heading: "5. Privacy Policy",
      paragraph:
        "Your privacy matters in the Metaloop Solutions universe. We are committed to keeping your data safe as we navigate the digital galaxy together. Your trust is the guiding star in our cosmic journey, ensuring your privacy is always a top priority.",
    },
    {
      heading: "6. Disclaimer of Warranties",
      paragraph:
        'Metaloop Solutions provides its services on an "as-is" and "as-available" basis. We do not guarantee the accuracy, completeness, or timeliness of information available on our website. Your use of our services is at your own risk.',
    },
    {
      heading: "7. Limitation of Liability",
      paragraph:
        "Metaloop Solutions and its affiliates, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.",
    },
    {
      heading: "8. Contact Information",
      paragraph:
        "If you have any questions or concerns about our privacy practices, we welcome you to reach out to our dedicated team. Our goal is to assist you and address any inquiries you may have. Your trust is of utmost importance to us, and we are steadfast in our commitment to making your digital journey with Metaloop Solutions secure, transparent, and worthy of your confidence.",
    },
  ];
  return (
    <div className="privacypolicy">
      <div className="privacytop">
        <h1>Terms and Conditions</h1>
        {/* <h3>Introduction</h3> */}
        <p>
          Welcome to Metaloops Solutions. By accessing our website and using our
          services, you agree to comply with and be bound by the following terms
          and conditions. Please read these terms carefully before using our
          services.
        </p>
      </div>
      {contentArray.map((content, index) => (
        <div className="privacycontent" key={index}>
          <h2>{content.heading}</h2>
          <p>{content.paragraph}</p>
        </div>
      ))}
      <div className="privacybottom">
        <p style={{ maxWidth: "975px", margin: "auto" }}>
          By using Metaloop Solutions' website and services, you agree to these
          terms and conditions.<br/>
           {/* Thank You For Choosing Us As Your Trusted
          Digital Partner! */}
          Thank you for choosing Metaloops Solutions.
        </p>
      </div>
    </div>
  );
};

export default Termscondition;
