import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Cards = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="card-wrapper" data-aos={props.animate}>
      <div className="cardElements">
        <div className="circle"></div>
        <div className="blockImgDiv">
          <img src={props.cardImg} alt="..." />
        </div>
        <h4>{props.cardText}</h4>
      </div>
    </div>
  );
};

export default Cards;
