import React from 'react';
import SlickSlider from './slickSlider';

const VoicesTrust = () => {
  return (
    <div className='voice-wrapper '>
        <h2 data-aos="zoom-out-up" className='h-center'>
        VOICES OF <span className="leftSpan_circle">TRU</span>ST
      </h2>
      <SlickSlider/>
    </div>
  )
}

export default VoicesTrust;