import React from 'react';
import TeamMember from './teamMember';

const Team = () => {

  const teamPic1 = "../../../images/home/team1.webp";
  const teamPic2 = "../../../images/home/team2.webp";
  const teamPic3 = "../../../images/home/team3.webp";

  const teamName1 = "Sarah Aileah";
  const teamName2 = "Jonathan Leon";
  const teamName3 = "Dean Joharness"

  const role1= "Manager";
  const role2= "CEO";
  const role3="Chief Marketing";

  return (
    <div className='team-wrapper'>
 <h2 data-aos="zoom-out-up" className='h-center'>  MEET OU<span className="rightSpan_circle">R TE</span>AM</h2>
 <img className='teamGridLeft' src="../../../images/home/footerGrid.png" alt="..." />
 <img className='teamGridRight' src="../../../images/home/teamGridRight.png" alt="..." />
 <div className="membersContainer">
 <TeamMember  animate="zoom-in-right" teamImages={teamPic1} teamMembers={teamName1} teamRole={role1}/>
 <TeamMember animate="zoom-in-up" teamImages={teamPic2} teamMembers={teamName2} teamRole={role2}/>
 <TeamMember animate="zoom-in-left" teamImages={teamPic3} teamMembers={teamName3} teamRole={role3}/>
 </div>

    </div>
  )
}

export default Team;