import React from "react";
import Heading from "./heading";

const WorkProcess = () => {
  const headName = "Work Place";
  return (
    <div className="workWrapper">
      <img
        className="glowLeft"
        src="../../../images/about/glowLeft.png"
        alt=""
      />
      <img
        className="glowRight"
        src="../../../images/about/glowRight.png"
        alt=""
      />
      <div className="h-center">
        <Heading
          className="text-center"
          // animate="zoom-out-up"
          haedingName={headName}
        />
      </div>

      <div className="workContent">
        <img
          src="../../../images/about/workBgLine.png"
          className="dottedLine"
        />
        <div className="workContentChild">
          <div className="circleWrapper">
            <div className="circleContainer"></div>
            <img src="../../../images/about/workImg1.webp" alt="" />
          </div>
          <h4>Discovery</h4>
        </div>

        <div className="workContentChild">
        <div className="circleWrapper">
          <div className="circleContainer"></div>
            <img src="../../../images/about/workImg2.webp" alt="" />
            </div>
          
          <h4>Planning</h4>
        </div>

        <div className="workContentChild">
        <div className="circleWrapper">
          <div className="circleContainer"></div>
            <img src="../../../images/about/workImg3.webp" alt="" />
            </div>
          
          <h4>Execute</h4>
        </div>

        <div className="workContentChild">
        <div className="circleWrapper">
          <div className="circleContainer"></div>
            <img src="../../../images/about/workImg4.webp" alt="" />
            </div>
      
          <h4>Deliver</h4>
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;
