import React from 'react'

const Future = () => {
  return (
    <div className='future-wrapper'>  
    <img className='futureSideBarleft' src="../../../images/home/teamSidebar.png" alt="" />
    <img className='futureSideBarright' src="../../../images/home/teamSidebar2.png" alt="" />
    
      <h2 data-aos="zoom-out-up" className='h-center'>
        Your Choices Shape  <span className="rightSpan_circle"> Futu</span>res!
      </h2>
      <h2 className='excellenceHead'>In a world of choices, opt for <span>excellence</span></h2>
      <div className='contentContainer'>
        <div data-aos="zoom-out-right" className="content-left"></div>

     
        <div  data-aos="zoom-out-left" className="content-right">
          <p>Every decision you make today becomes a building block for tomorrow's future.</p>
          <div className="right-box-data">
            <div className="data1">
            <div className="right-box-data-Pleft p-3">
              <img src="../../../images/home/future-bulb-img.png" alt="..." />
            </div>
            <div className="right-box-data-Pright">
              <h5>Tech Vanguard</h5>
              <p>Embracing digital transformation to stay at the forefront of industry evolution</p>
            </div>
            </div>
            


            <div className="data1">
            <div className="right-box-data-Pleft ">
              <img src="../../../images/home/future-hand-img.png" alt="..." />
            </div>
            <div className="right-box-data-Pright">
              <h5>Innovation Hub</h5>
              <p>Nurturing a culture of continuous improvement and forward-thinking</p>
            </div>
            </div>





            <div className="data1">
            <div className="right-box-data-Pleft">
              <img src="../../../images/home/future-clock-img.png" alt="..." />
            </div>
            <div className="right-box-data-Pright">
              <h5>On-Time Assurance</h5>
              <p>Timely delivery is not just a promise, it's our practice</p>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absoluteDiv">
        <div className="absolute-div-left">
          
        </div>
        <div className="absolute-div-right">
          <p>Digital Marketing</p>
          <div className='progressBar1'>  </div>

          <p>Web Design & Development</p>
          <div className='progressBar2'>  </div>

          <p>App Development</p>
          <div className='progressBar3'>  </div>

          <p>Blockchain Development</p>
          <div className='progressBar1'>  </div>
        </div>
      </div>
    </div>
  )
}

export default Future;