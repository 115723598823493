import React, { useEffect } from "react";
import SBanner from "../components/about/sBanner";
import LatestProjects from "../components/portfolio/latestProjects";
import SubmitForm from "../components/home/submitForm";
import PortfolioBanner from "../components/portfolio/portfolioBanner";
import AOS from "aos";
import "aos/dist/aos.css";
const Portfolio = (props) => {
  const portfolioB = "Portfolio";
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
   <PortfolioBanner/>
      {/* <SBanner name={portfolioB} /> */}
      <LatestProjects/>
      <SubmitForm/>
    </>
  );
};

export default Portfolio;
