import React from "react";
import SlickCards from "./slickCards";
import Slider from "react-slick";

const SlickSlider = () => {
  const imgSlider1 = "../../../images/home/slider-cardImg1.png";
  const imgSlider2 = "../../../images/home/slider-cardImg2.png";
  const imgSlider3 = "../../../images/home/slider-cardImg3.png";

  const head1 = "Samantha H. -t.";
  const head2 = "Jane K";
  const head3 = "Chris W";

  const parag1 =
    "Metaloops Solutions consistently delivers Solutions that redefine the technological landscape. Their adaptability and commitment to quality have made them an integral part of our growth. Working with Metaloop is an assurance of innovation and excellence.";
  const parag2 =
    "Metaloops Solutions is a game-changer in the tech industry. Their commitment to excellence and innovative Solutions has transformed how we approach digital challenges. Working with Metaloop has been a journey of reliability and brilliance.";
  const parag3 =
    "Metaloops Solutions is a beacon of excellence in the realm of Technology. Their comprehensive understanding of financial technologies and their ability to translate complex requirements into seamless Solutions make them an invaluable partner. With Metaloop, innovation is not just promised; it's delivered.";
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} className="custom-slider">
        <SlickCards
          cardsPics={imgSlider1}
          cardsHead={head1}
          cardParag={parag1}
        />
        <SlickCards
          cardsPics={imgSlider2}
          cardsHead={head2}
          cardParag={parag2}
        />
        <SlickCards
          cardsPics={imgSlider3}
          cardsHead={head3}
          cardParag={parag3}
        />
        <SlickCards
          cardsPics={imgSlider1}
          cardsHead={head1}
          cardParag={parag1}
        />
      </Slider>
    </>
  );
};

export default SlickSlider;
