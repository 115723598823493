import React, { useEffect } from 'react';
import Banner from '../components/home/banner';
import SBanner from '../components/about/sBanner';
import OurMission from '../components/about/ourMission';
import WorkProcess from '../components/about/workProcess';
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {

  const bannerName= "Our Story";

  useEffect(() => {
    AOS.init();
  }, []);
  return (
   <div id='about'>
    
<SBanner name={bannerName} />
 <OurMission/>
<WorkProcess/>
   </div>
  )
}

export default AboutUs;