import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "./shared/navbar";
import Footer from "./shared/footer";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Privacypolicy from "./components/privacy/privacypolicy";
import Termscondition from "./components/privacy/termscondition";
import Pagenotfound from "./components/404/pagenotfound";
import Blogs from "./pages/blogs";
import Portfolio from "./pages/portfolio";
import Services from "./pages/services";
import ContactUs from "./pages/contactUs";

function App() {
  const location = useLocation();
  const { pathname } = location;
  const isValidRoute = [
    "/",
    "/about",
    "/privacy-policy",
    "/termscondition",
    "/blogs",
    "/portfolio",
    "/services",
    "/contact"
  ].includes(pathname);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isValidRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/termscondition" element={<Termscondition />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* If the route is not found, render the 404 page */}
        {!isValidRoute && <Route path="*" element={<Pagenotfound />} />}
      </Routes>
      {isValidRoute && <Footer />}
      {/* Scroll to top button */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47px"
        height="47px"
        viewBox="0.9 0.9 22 22"
        className="topscroll"
        onClick={scrollToTop}
        style={{
          background: "white",
          color: "#0072ff",
          display: `${scrollPosition > 1080 ? "" : "none"}`,
        }}
      >
        <g fill="#0072ff">
          <path d="m14.854 11.974l1.415-1.414l-4.243-4.243l-4.243 4.243l1.414 1.414l1.829-1.828v7.537h2v-7.537z" />
          <path
            fill-rule="evenodd"
            d="M1 19a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4zm4 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2"
            clip-rule="evenodd"
          />
        </g>
      </svg>
    </>
  );
}

export default App;
