import React from 'react'
import "./pagenotfound.scss"
import img1 from "../../assets/images/pagenotfound.png"
import { Link } from 'react-router-dom'
const Pagenotfound = () => {
  return (
    <div className='pagenotfound'>
      <div className="pageleftcontainer">
        <img src={img1} alt="" />

      </div>
      <div className="pagerightcontainer">
        <h1>404</h1>
        <h4>Page not Found</h4>
        <Link to="/"><button>
            Go Home
        </button></Link>
        
      </div>

    </div>
  )
}

export default Pagenotfound
