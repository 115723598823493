import React, { useEffect } from "react";
import "./cardContent.scss";
import pic1 from "../../../assets/images/inner1.webp";
import innerback from "../../../assets/images/innerback.png";
import AOS from "aos";
import "aos/dist/aos.css"; 

const CardContent = ({
  image,
  heading,
  subheading,
  subheading2,
  subheading3,
  subheading4,
  content1,
  content2,
  content3,
  content4,
  content5,
  content6,
  content7,
  animate,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos={animate} className="card-main-container">
      <div className="card-inner-content">
        <div className="inner-back-container">
          <img src={image} alt="" />
        </div>
        <div className="inner-upper-container">
          <div className="upper-header">
            <h2>{heading}</h2>
          </div>
          <div className="upper-content">
            <h1>{subheading}</h1>
            <p>{content1}</p>
            <p>{content2} </p>
            <p>{content3} </p>
           
            <h4>{subheading2}</h4>
            <p>{content4} </p>
            <h4>{subheading3}</h4>
            <p>{content5} </p>
            
            <h4>{subheading4}</h4>
            <p>{content6}</p>
            <p>{content7}</p>
           
          </div>
        </div>
      </div>
      <div className="inner-bg-container">
        <img src={innerback} alt="" />
      </div>
    </div>
  );
};

export default CardContent;
