import React from 'react'
import "./servicesBanner.scss"

const ServicesBanner = () => {
  return (
    <div className='serviceBanner'>
    <h1 data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500" className='text-white'>Services</h1>
  </div>
  )
}

export default ServicesBanner
