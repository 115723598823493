import React, { useEffect } from "react";
import ProjectCards from "./projectCards";

const LatestProjects = () => {
  const bgimg1 = "../../../images/portfolio/resumeLanding.webp";
  const bgimg2 = "../../../images/portfolio/dispatchLanding.png";
  const bgimg3 = "../../../images/portfolio/dmBotLanding.webp";
  const bgimg4 = "../../../images/portfolio/simpliLanding.webp";
  const bgimg5 = "../../../images/portfolio/nextGen.webp";
  const bgimg6 = "../../../images/portfolio/neoAILanding.webp";
  const bgimg7 = "../../../images/portfolio/metaMuzeLanding.webp";
  const bgimg8 = "../../../images/portfolio/domainLanding.webp";
  const bgimg9 = "../../../images/portfolio/trunkLanding.webp";
  const bgimg10 = "../../../images/portfolio/vitraLanding.webp";

  const projName1 = "Resume";
  const projName2 = "Dispatch AI";
  const projName3 = "DM Bot";
  const projName4 = "Simpli RFP";
  const projName5 = "NEXGEN";
  const projName6 = "NEO AI";
  const projName7 = "Metamuze";
  const projName8 = "Domain Lane";
  const projName9 = "Truck Mint";
  const projName10 = "Vitra Mittal";

  const projDetail1 =
    "Resume AI transforms resumes with personalized suggestions, stunning designs, and ATS-optimized formatting, making the process revolutionary and user-friendly.";
  const projDetail2 =
    " Dispatch AI transforms logistics, optimizing supply chain efficiency seamlessly from warehouses to petrol stations, ushering in a revolutionary era for streamlined operations.";
  const projDetail3 =
    "Introducing DM Bot an amazing project - revolutionizing direct messaging with state-of-the-art technology, ensuring seamless and efficient communication.";

  const projDetail4 =
    "Revolutionize your business processes with the groundbreaking power of Simpli RFP. Experience unparalleled efficiency and innovation in blockchain and virtual experiences.";

  const projDetail5 =
    "Nexgen is an innovative app with secure APIs, instant conversions, offline modes, and user-friendly interfaces. Reach out to experts for building-related issues or mismanagement.";

  const projDetail6 =
    "Neo AI's virtual infinity is a decentralized metaverse offering 100k land plots for purchase and rent. The platform introduces the VIC token, featuring a 15% daily rental tax and a distinctive advertising opportunity. ";

  const projDetail7 =
    "Metamuze is your go-to marketplace on Solana for NFTs and physical items. Powered by Metaloop Solutions, it's cheat-proof, creator-empowering, and a dynamic hub for secure transactions.";

  const projDetail8 =
    "Embark on a journey to a futuristic digital realm with Domain Lane, where domains and NFTs converge. Bid farewell to the ordinary and welcome the extraordinary in this innovative web3 platform!";
  const projDetail9 =
    "Truck Mint revolutionizes trucking with its innovative approach, featuring Tesla Truck NFTs and securing collaboration through the Commute Bridge. Explore the future of logistics with seamless connectivity and enhanced efficiency.";

  const projDetail10 =
    "Welcome to the world of Vitral Mitral, an extraordinary fusion of artisan structures, contemporary staircases, and cutting-edge glass expertise.";

    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="projectsWrapper">
      <img
        className="glowLeft"
        src="../../../images/about/glowLeft.png"
        alt=""
      />
      <img
        className="glowRight"
        src="../../../images/about/glowRight.png"
        alt=""
      />
      <h2 data-aos="zoom-out-up" className="h-center mt-5">
        {" "}
        <span className="leftSpan_circle">Our </span>&nbsp;Latest Projects{" "}
      </h2>
      <div className="ourProjectContainer">
        <img
          className="btmGlowLeft"
          src="../../../images/about/bottomGlow.png"
          alt=""
        />
        <img
          className="btmGlowRight"
          src="../../../images/about/bottomGlowRight.png"
          alt=""
        />
        <ProjectCards
          cardBg={bgimg1}
          projectName={projName1}
          projectDetails={projDetail1}
        />
        <ProjectCards
          cardBg={bgimg2}
          projectName={projName2}
          projectDetails={projDetail2}
        />
        <ProjectCards
          cardBg={bgimg3}
          projectName={projName3}
          projectDetails={projDetail3}
        />
        <ProjectCards
          cardBg={bgimg4}
          projectName={projName4}
          projectDetails={projDetail4}
        />
        <ProjectCards
          cardBg={bgimg5}
          projectName={projName5}
          projectDetails={projDetail5}
        />
        <ProjectCards
          cardBg={bgimg6}
          projectName={projName6}
          projectDetails={projDetail6}
        />
        <ProjectCards
          cardBg={bgimg7}
          projectName={projName7}
          projectDetails={projDetail7}
        />
        <ProjectCards
          cardBg={bgimg8}
          projectName={projName8}
          projectDetails={projDetail8}
        />
        <ProjectCards
          cardBg={bgimg9}
          projectName={projName9}
          projectDetails={projDetail9}
        />
        <ProjectCards
          cardBg={bgimg10}
          projectName={projName10}
          projectDetails={projDetail10}
        />
      </div>
    </div>
  );
};

export default LatestProjects;
