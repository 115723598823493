import React from 'react';
import Banner from '../components/contact/banner';
import GetInTouch from '../components/contact/getInTouch';
import Map from '../components/contact/map';

const ContactUs = () => {
  return (
  <>
  <Banner/>
  <GetInTouch/>
  <Map/>
  </>
  )
}

export default ContactUs;