import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const Faq = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionChange = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  return (
    <div className="faqWrapper">
      <h2 data-aos="zoom-out-up" className="h-center">
        FREQUENTLY ASK<span className="rightSpan_circle">ED Q</span>UESTION
      </h2>
      <div className="faq-container">
        <div className="faq-left">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordianHeader">
                What services does Metaloop Solutions offer?
              </Accordion.Header>
              <Accordion.Body>
                Metaloop Solutions provides diverse tech services, including
                blockchain development, AI Solutions, game development,
                metaverse experiences, and more.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How does Metaloop Solutions approach innovation?
              </Accordion.Header>
              <Accordion.Body>
                Innovation is at our core. We continually explore cutting-edge
                technologies, create bespoke Solutions, and maintain an agile
                approach to stay ahead in the dynamic tech landscape
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can Metaloop Solutions handle projects of different scales?
              </Accordion.Header>
              <Accordion.Body>
                Absolutely. We cater to projects of varying sizes, from startups
                to established enterprises. Our adaptable teams ensure that each
                project receives the attention and expertise it deserves.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What sets Metaloop Solutions apart in the tech industry?
              </Accordion.Header>
              <Accordion.Body>
                Metaloop Solutions stands out for its commitment to excellence,
                innovative Solutions, and a client-centric approach. Our diverse
                portfolio and successful collaborations underscore our industry
                distinction.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          className="faq-right"
        >
          <img src="../../../images/home/faqImage.webp" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Faq;
