import React from "react";

const Brand = () => {
  return (
    <div className="brand-wrapper">
      <h2 data-aos="zoom-out-up">
        Brands We Proudly St<span className="rightSpan_circle">And </span>{" "}
        Beside
      </h2>
      <p>
        Explore our portfolio of client-centric projects and the journeys we've
        shared in building remarkable Solutions
      </p>
      <marquee
        width="100%"
        direction="left"
        height="130px"
        scrollamount="15"
        scrolldelay="2"
      >
        <div className="brand-logo-Container">
          <div className="appleLogo">
            <img src="../../../images/home/b-logo1.png" alt="..." />
          </div>

          <div className="appleLogo">
            <img
              className="mt-2"
              src="../../../images/home/b-logo2.png"
              alt="..."
            />
          </div>

          <div className="appleLogo">
            <img src="../../../images/home/b-logo3.png" alt="..." />
          </div>

          <div className="appleLogo">
            <img src="../../../images/home/b-logo4.png" alt="..." />
          </div>

          <div className="appleLogo">
            <img
              className="mt-2"
              src="../../../images/home/b-logo5.png"
              alt="..."
            />
          </div>

          <div className="appleLogo">
            <img src="../../../images/home/b-logo6.png" alt="..." />
          </div>
        </div>
      </marquee>
    </div>
  );
};

export default Brand;
