import React from 'react';
import portfolioVideo from "../../../src/assets/videos/portfolioVideo.mp4";

const PortfolioBanner = () => {
  return (
    <div className='portBanner'>
        <h1  data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500"  className='text-white h-center'>Portfolio</h1>
         <div className="hero-vid">
            <video loop autoPlay playsInline muted>
              <source
                src={portfolioVideo}
                type="video/mp4"
              />
            </video>
          </div>
    </div>
  )
}

export default PortfolioBanner;