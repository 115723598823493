import card1 from "../assets/images/card1.png";
import card2 from "../assets/images/card2.png";
import card3 from "../assets/images/card3.png";
import card4 from "../assets/images/card4.png";
import card5 from "../assets/images/card5.png";
import card6 from "../assets/images/card6.png";
import card7 from "../assets/images/card7.png";
import card8 from "../assets/images/card8.png";
import card9 from "../assets/images/card9.png";

export const cardData = [
  {
    id: 1,
    image: card1,
    heading: "Web 3.0",
    content:
      "Unveil the future of the internet with Web 3.0. Explore the decentralized, interactive landscape that is reshaping online experiences, promising a...",
    statevalue: "web3",
  },
  {
    id: 2,
    image: card2,
    heading: "Innovation in Sales",
    content:
      "Step into the world of modern sales, where cool tech is changing the game! Imagine talking to super-smart computer helpers when you are shopping, or ...",
    statevalue: "innovation",
  },
  {
    id: 2,
    image: card3,
    heading: "Customized IT Solutions",
    content:
      "n today is dynamic business landscape, the notion of one-size-fits-all technology is fading away, making room for the era of customized IT Solutions. In the...",
    statevalue: "it-Solutions",
  },
  {
    id: 3,
    image: card4,
    heading: "Game Development",
    content:
      "In the dynamic realm of game development, industry trends play a pivotal role in shaping the gaming experience. From the widespread popularity of casual...",
    statevalue: "gamedevelopment",
  },
  {
    id: 4,
    image: card5,
    heading: "Navigating the Future",
    content:
      "In an era defined by technological leaps, artificial intelligence (AI) has emerged as a transformative force, reshaping industries and redefining business landscapes...",
    statevalue: "navigatefuture",
  },
  {
    id: 5,
    image: card6,
    heading: "Blockchain Development",
    content:
      "Welcome to the world of limitless possibilities, where innovation meets technology. At Metaloop Solutions, we pride ourselves on being at the forefront...",
    statevalue: "blockchain",
  },
  {
    id: 6,
    image: card7,
    heading: "Masters of Metaverse",
    content:
      "In the vast expanse of the digital realm, a revolutionary concept has taken center stage, captivating the imagination of individuals and businesses alike...",
    statevalue: "metaverse",
  },
  {
    id: 7,
    image: card8,
    heading: "Machine Learning",
    content:
      "In today is data-driven world, the sheer volume of information available can be overwhelming. Companies and individuals alike are seeking ways to...",
    statevalue: "machine-learning",
  },
  {
    id: 8,
    image: card9,
    heading: "NFTs Unleashed",
    content:
      "In the dynamic landscape of the digital age, a revolutionary trend has surfaced, reshaping our perception and interaction with art and creativity....",
    statevalue: "nfts",
  },
];
