import React from 'react';
import "./blogBanner.scss";

const Blogbanner = ({ goBack, showGoBack }) => {
  return (
    <div className='blogBanner'>
      <h1  data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500" className='text-white'>Explore our Insights</h1>
      {showGoBack && <button onClick={goBack}>Go Back</button>}
    </div>
  );
};

export default Blogbanner;
