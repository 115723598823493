import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

const Banner = () => {

    useEffect(() => {
        AOS.init();
      }, []);
  return (
  <div className='contactBanner'>
    <h2 data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500" className='text-white'>Contact us </h2>
  </div>
  )
}

export default Banner;