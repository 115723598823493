import React from 'react'

const SBanner = (props) => {
  return (
    <div className='aboutBaner'>
      <h1 data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500" className='text-white'>{props.name}</h1>
    </div>
  )
}

export default SBanner;