import React from "react";
import 'animate.css';

const SlickCards = (props) => {
  return (
    <div className="slickCard-wrapper ">
      <div className="card-part1">
        <h5>{props.cardsHead}</h5>
        <p className="text-gray">
          COO, Innovate <br /> Industries Ltd
        </p>
        <p className="starsDiv"></p>
      </div>
      <div className="card-part2">
        <img src={props.cardsPics} alt="" />
        <p>
          {props.cardParag}
        </p>
      </div>
    </div>
  );
};

export default SlickCards;
