import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubmitForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear error when user starts typing in a field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    // const { username, email, phone, description} = formData;
    e.preventDefault();

    // if (!username || !email || !phone || !description) {
    //   newErrors="Fields are empty";
    //   return;
    // }

    // Perform validations
    const newErrors = {};
    const requiredFields = ["name", "phone", "email", "description"];
    const emptyFields = requiredFields.filter(field => !formData[field]);
    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields");
      return;
    }
    // if (!formData.name) {
    //   newErrors.name = "Please enter name";
    // }
    // if (!formData.phone) {
    //   newErrors.phone = "Please enter number";
    // }
    if (!formData.phone) {
      newErrors.phone = "Please enter number";
    } else if (formData.phone.length !== 11) {
      newErrors.phone = "Phone number must be 11 digits";
    }

    // const phoneRegex = /^[0-9]{10}$/;
    // if (!formData.phone || !phoneRegex.test(formData.phone)) {
    //   newErrors.phone = 'Please enter a valid phone number.';
    // }

    // if (formData.phone.length !== 11) {
    //   newErrors.phone = "Phone number must be 11 digits";
    // }

    // if (!formData.email) {
    //   newErrors.email = "Please enter an email";
    // }

    // if (!formData.description) {
    //   newErrors.description = "Please enter description";
    // }

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (formData.email && !emailRegex.test(formData.email)) {
    //   newErrors.email = "Please enter a valid email address";
    // }
    if (Object.keys(newErrors).length > 0) {
      // Display error toasts and update state for highlighting
      Object.keys(newErrors).forEach((key) => {
        toast.error(newErrors[key]);
      });
      setFormErrors(newErrors);
      return;
    }
    toast.success("Form submitted successfully!");

    setFormData({
      name: "",
      phone: "",
      email: "",
      description: "",
    });
  };

  return (
    //     <div className='form-wrapper'>
    //       <img className='formLeftGrid' src="../../../images/home/contactFormGridD.png" alt="" />
    //       <img className='formRightGrid' src="../../../images/home/contactFormGridD.png" alt="" />
    //         <h3 className='text-center'>GROW YOUR BUSINESS &</h3>
    //         <h4 className='mb-4 text-center'>EXPERIENCE ENDLESS POSIBILITIES</h4>
    //         <div className="formBox">
    //         <div className="namePhoneWrapper d-flex" >
    //             <input type="text" placeholder='Name'  />
    //             <input type="number" placeholder='Phone' />
    //             </div>

    //             <input type="email" placeholder='Email' />
    //             <input type="text" placeholder='Description' className='description' />
    //             <button className=''>Submit</button>
    //         </div>
    //     </div>
    //   )
    // }

    <div className="form-wrapper">
      <img
        className="formLeftGrid"
        src="../../../images/home/contactFormGridD.png"
        alt=""
      />
      <img
        className="formRightGrid"
        src="../../../images/home/contactFormGridD.png"
        alt=""
      />
      <h3 className="text-center">GROW YOUR BUSINESS &</h3>
      <h4 className="mb-4 text-center">EXPERIENCE ENDLESS POSSIBILITIES</h4>
      <form
        data-aos="flip-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
        onSubmit={handleSubmit}
      >
        <div className="formBox">
          <div className="divCombine d-flex gap-5">
            <div
              className={`namePhoneWrapper d-flex ${
                formErrors.name ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
              />
            </div>
            {/* {formErrors.name && <span className="error-message">{formErrors.name}</span>} */}
            <div
              className={`namePhoneWrapper d-flex ${
                formErrors.phone ? "error" : ""
              }`}
            >
              <input
                type="tel" // Change type to "tel" for phone numbers
                inputMode="numeric"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                 pattern="[0-9]*" 
              />
            </div>
          </div>

          {/* {formErrors.phone && <span className="error-message">{formErrors.phone}</span>} */}

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />

          {/* {formErrors.email && <span className="error-message">{formErrors.email}</span>} */}
          <textarea
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            className="description"
          ></textarea>

          <button type="submit">Submit</button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
export default SubmitForm;
