import React, { useEffect } from "react";
import bannerVideo from "../../../src/assets/videos/bannerVideo.mp4";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Banner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="scrollTop" className="h-banner">
      <div className="sideGlow"> </div>
      <div className="sideGlowRight"> </div>
      <div className="banner-center-div">
        <h1 className="banner-head animate__animated animate__fadeInDown">
          Guiding Your <br /> Business into Tech Excellence
        </h1>
        <p className="animate__animated animate__fadeIn">
          Transform your business with our smart IT Solutions, shaping the
          future of technology. We're here to make your success journey
          straightforward and filled with infinite opportunities
        </p>
        <Link to="/services"> <button className="gradient-blue-button mx-auto animate__animated animate__fadeInUp">
          Explore
        </button></Link>
       
      </div>
      <div className="gray-layer"></div>

      <div className="hero-vid">
        <video loop autoPlay playsInline muted>
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>
      {/* <div className="semiCircleGlow"></div> */}
    </div>
  );
};

export default Banner;
