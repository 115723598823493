import React from "react";

const Heading = (props) => {
  return (
    <>
      <h2  data-aos={props.animate} className="text-center" >
        <span className="leftSpan_circle  ">Our</span> {props.haedingName}
      </h2>
    </>
  );
};

export default Heading;
